'use client'

import { ReactNode } from 'react'

import { BreadcrumbItem } from '@sikt/sds-breadcrumbs'
import { Button } from '@sikt/sds-button'
import { Heading1 } from '@sikt/sds-core'
import { ArrowLeftIcon } from '@sikt/sds-icons'
import { useTranslations } from 'next-intl'

import { Link } from '../Link'
import { Breadcrumbs } from '@/components/Breadcrumbs/Breadcrumbs'
import { useTypedRouter } from '@/hooks/useTypedRouter/useTypedRouter'
import { RouteHref } from '@/types/routes'

import styles from './PageArrangement.module.css'

interface PageArrangementProps {
  title: string
  breadcrumbs?: { title: string; href: RouteHref }[]
  children: ReactNode
  footer?: ReactNode
  enableBack?: boolean
}

export const PageArrangement = ({
  title,
  breadcrumbs,
  children,
  footer,
  enableBack,
}: PageArrangementProps) => {
  const t = useTranslations('common')
  const router = useTypedRouter()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Heading1 variant="medium">{title}</Heading1>

        {enableBack && (
          <div>
            <Button
              icon={<ArrowLeftIcon />}
              iconVariant="left"
              variant="transparent"
              onClick={() => {
                router.back()
              }}
              size="small"
            >
              {t('back')}
            </Button>
          </div>
        )}

        {breadcrumbs && (
          <Breadcrumbs>
            {breadcrumbs.map((item, i) => (
              <BreadcrumbItem
                key={
                  typeof item.href === 'string'
                    ? item.href + item.title
                    : item.href.pathname + item.title
                }
              >
                <Link href={item.href} aria-current={i === breadcrumbs.length - 1 && 'page'}>
                  {item.title}
                </Link>
              </BreadcrumbItem>
            ))}
          </Breadcrumbs>
        )}

        <div className={styles.children}>{children}</div>

        <div className={styles.footer}>{footer && footer}</div>
      </div>
    </div>
  )
}
