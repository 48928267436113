import { ReactNode } from 'react'

import { BreadcrumbItem, Breadcrumbs as SiktBreadcrumbs } from '@sikt/sds-breadcrumbs'
import { useTranslations } from 'next-intl'

import { Link } from '@/components/Link'

import styles from './Breadcrumbs.module.css'

interface BreadcrumbsProps {
  children?: ReactNode
}

export function Breadcrumbs({ children }: BreadcrumbsProps) {
  const t = useTranslations('components.Breadcrumbs')

  return (
    <SiktBreadcrumbs aria-label={t('label')} className={styles.breadcrumbs}>
      <BreadcrumbItem>
        <Link href="/" aria-current={!children ? 'page' : undefined}>
          {t('home')}
        </Link>
      </BreadcrumbItem>
      {children}
    </SiktBreadcrumbs>
  )
}
